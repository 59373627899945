import React from 'react';
// import presets from "../utils/presets";
// import {rhythm} from "../utils/typography";
import {Link} from 'gatsby'
// import Img from 'gatsby-image'
import Helmet from 'react-helmet'

import Container from "../com/Container"
import Footer from "../com/Footer"
import Diagonal from "../com/Diagonal"
// import Nav from "../com/Nav"

import Section from "../com/Section"

import H2 from "../com/H2"

import P from "../com/P"

import LayoutMain from "../com/LayoutMain"



const ThankYouPage = ({ data }) => (
<LayoutMain>
  <Helmet>
    <title>Thank You</title>
    <meta name="description" content="Thanks!" />
  </Helmet>
<header>
  <Diagonal>
    <Container>
        <H2>thank you</H2>
        <P>
          We will get back to you as soon as we can.
        </P>
    </Container>
    {/* <IconArrowDown /> */}
  </Diagonal>
</header>
<Container center>
  <Section>
    <H2 center id="work">See Our Work</H2>
    <P>
      <a
        href="https://www.facebook.com/Dec-Hands-Ltd-779659805434007/photos" target="_blank"
        rel="noopener noreferrer">
        View some of our handywork on our Facebook gallery.
      </a>
    </P>
    <Link to="/" brand>Back to our home page</Link>
  </Section>
  <Footer />
</Container>

</LayoutMain>);

export default ThankYouPage
